import websocket from "@/store/modules/websocket";
export default {

    // 初始化websocket
    initWebSocket(topic, callback) {

        let interval = setInterval(()=> {

            let stompClient = websocket.getters.stompClient();
            if (stompClient != null && stompClient.connected) {

                // 连接成功后清除定时器
                clearInterval(interval);
                // 订阅主题
                let id = new Date().getTime() + topic;
                stompClient.subscribe(topic, callback, {id: id});
                // 将订阅的信息存储到全局变量中
                let subInfo = {topic: topic, callback: callback, id: id};
                websocket.dispatch("addListener", subInfo);
                console.info("订阅成功：" + JSON.stringify(subInfo));
            } else {

                console.log("等待连接成功...")
            }
        },500);
    },
    // websocket取消订阅
    unSubTopic(topic) {

        websocket.dispatch("unSub", topic);
    }
}