<template>
    <div v-show="engineVisible">
        <el-form :model="engineForm" ref="engineForm" class="engineForm" :inline="true">
            <el-row style="margin-bottom: 12px;">
                <el-col :span="12" style="height: 340px;border: 0px red solid;">
                    <fieldset style="border:1px solid #ccc;background-color: #ECF0F5;height:100%;position: relative">
                        <legend style="font-weight: bolder;">操作</legend>
                        <el-row>
                            <el-form-item>
                                <div style="font-weight: bolder;font-size: 30px;text-align: center;margin-left:90px;margin-top: 40px;">
                                    <span>发电机状态：</span>
                                    <el-image :src="deviceImage" style="width: 30px;height: 30px"></el-image>
                                </div>
                            </el-form-item>
                        </el-row>
                        <el-row style="margin-left: 120px">
                            <el-row style="margin-top: 80px">
                                <el-col :span="5">
                                    <el-form-item>
                                        <el-button size="small" type="success" @click="handleDeviceOption(1)">启动</el-button>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="19" style="margin-top: 13px">
                                    <el-form-item>
                                        <div class="light" :class="{greenLight:deviceStart}"></div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="5">
                                    <el-form-item>
                                        <el-button size="small" type="success" @click="handleDeviceOption(2)">停止</el-button>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="19"  style="margin-top: 13px">
                                    <el-form-item>
                                        <div class="light" :class="{greenLight:deviceStop}"></div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="5">
                                    <el-form-item>
                                        <el-button size="small" type="success" @click="handleDeviceOption(3)">自动</el-button>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="19" style="margin-top: 13px">
                                    <el-form-item>
                                        <div class="light" :class="{greenLight:deviceAuto}"></div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="5">
                                    <el-form-item>
                                        <el-button size="small" type="success" @click="handleDeviceOption(4)">手动</el-button>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="19" style="margin-top: 13px">
                                    <el-form-item>
                                        <div class="light" :class="{greenLight:deviceManu}"></div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="5">
                                    <el-form-item>
                                        <el-button size="small" type="success" @click="handleDeviceOption(5)">{{heZhaText}}</el-button>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="19" style="margin-top: 13px">
                                    <el-form-item>
                                        <div class="light" :class="{greenLight:deviceSwitch}"></div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-row>
                    </fieldset>
                </el-col>
                <el-col :span="12" style="height: 340px;border: 0px red solid;">
                    <fieldset style="border:1px solid #ccc;background-color: #ECF0F5;height:100%;position: relative">
                        <legend style="font-weight: bolder;">发电</legend>
                        <el-row style="margin-left: 28px;margin-top: 30px">
                            <el-col :span="6">
                                <el-form-item label="交流电压："></el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item> {{this.engineForm.genOutAcv}} V </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="发电频率："></el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item> {{this.engineForm.genFrequency}} Hz </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 28px;margin-top: 15px">
                            <el-col :span="6">
                                <el-form-item label="有功功率："></el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item> {{this.engineForm.genRealP}} kW </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="无功功率："></el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item> {{this.engineForm.genReactiveP}} kW </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 28px;margin-top: 15px">
                            <el-col :span="6">
                                <el-form-item label="视在功率："></el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item> {{this.engineForm.genApparentP}} kVA </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="功率因数："></el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item> {{this.engineForm.genPFactor}} </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 28px;margin-top: 15px">
                            <el-col :span="6">
                                <el-form-item label="交流电流："></el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item> {{this.engineForm.genOutAvi}} A </el-form-item>
                            </el-col>
                        </el-row>
                    </fieldset>
                </el-col>
            </el-row>
            <el-row style="margin-bottom: 12px;">
                <el-col :span="12" style="height: 260px;border: 0px red solid;">
                    <fieldset style="border:1px solid #ccc;background-color: #ECF0F5;height:100%;position: relative">
                        <legend style="font-weight: bolder;">状态</legend>
                        <el-row style="margin-left:28px;margin-top: 10px">
                            <el-col :span="6">
                                <el-form-item label="运行状态："></el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-form-item> {{this.engineForm.genStatus}} </el-form-item>
                            </el-col>
                            <el-col :span="7">
                                <el-form-item label="铅酸电池电压："></el-form-item>
                            </el-col>
                            <el-col :span="7">
                                <el-form-item> {{this.engineForm.genVrlaBatV}} V </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left:28px;margin-top: 15px">
                            <el-col :span="6">
                                <el-form-item label="机油压力："></el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item> {{this.engineForm.engPressure}} kPa </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="机油温度："></el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item> {{this.engineForm.engOilTemp}} 度 </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left:28px;margin-top: 15px">
                            <el-col :span="6">
                                <el-form-item label="燃油液位："></el-form-item>
                            </el-col>
                            <el-col :span="5">
                                <el-form-item> {{this.engineForm.engLiquidLevel}} %</el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-form-item> {{this.engineForm.engFuelCount}} L</el-form-item>
                            </el-col>
                            <el-col :span="9">
                                <el-form-item>
                                    <el-button class="totalButton" @click="openEngFuelCountAnalysis()">统计</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left:28px;margin-top: 15px">
                            <el-col :span="6">
                                <el-form-item label="冷却液温度："></el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-form-item> {{this.engineForm.engWaterTemp}} 度</el-form-item>
                            </el-col>
                            <el-col :span="7">
                                <el-form-item label="转速："></el-form-item>
                            </el-col>
                            <el-col :span="7">
                                <el-form-item> {{this.engineForm.engRpm}} r/min </el-form-item>
                            </el-col>
                        </el-row>
                    </fieldset>
                </el-col>
                <el-col :span="12" style="height: 260px;border: 0px red solid;">
                    <fieldset style="border:1px solid #ccc;background-color: #ECF0F5;height:100%;position: relative">
                        <legend style="font-weight: bolder;">统计</legend>
                        <el-row style="margin-left: 28px;margin-top: 10px">
                            <el-col :span="7">
                                <el-form-item label="累计运行时间："></el-form-item>
                            </el-col>
                            <el-col :span="17">
                                <el-form-item>
                                    {{this.engineForm.genRunningTimeHour}} h
                                    {{this.engineForm.genRunningTimeMin}} m
                                    {{this.engineForm.genRunningTimeSec}} s
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 28px;margin-top: 15px">
                            <el-col :span="7">
                                <el-form-item label="累计开机次数："></el-form-item>
                            </el-col>
                            <el-col :span="17">
                                <el-form-item>{{this.engineForm.powerOnCount}}</el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 28px;margin-top: 15px">
                            <el-col :span="7">
                                <el-form-item label="累计发电量："></el-form-item>
                            </el-col>
                            <el-col :span="17">
                                <el-form-item>{{this.engineForm.genTotalEnergy}} kWh</el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-left: 28px;margin-top: 15px">
                            <el-col :span="7">
                                <el-form-item label="维护倒计时："></el-form-item>
                            </el-col>
                            <el-col :span="17">
                                <el-form-item>
                                    {{this.engineForm.genCountdownHour}} h
                                    {{this.engineForm.genCountdownMin}} m
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </fieldset>
                </el-col>
            </el-row>
            <el-row style="margin-bottom: 18px;">
                <fieldset style="border:1px solid #ccc;background-color: #ECF0F5;height:30px;position: relative">
                    <legend style="font-weight: bolder;">市电</legend>
                    <el-row style="height: 20px;margin-top: -15px;">
                        <el-form-item label="交流电压：">{{this.engineForm.mainsV}} V</el-form-item>
                        <el-form-item style="margin-left: 25px" label="频率：">{{this.engineForm.mainsFrequency}} Hz</el-form-item>
                    </el-row>
                </fieldset>
            </el-row>
            <el-row style="text-align: right;margin-top: -12px;">
                <label style="font-weight: bolder">{{this.engineForm.dtuWorkStatus}} 最后上报时间：</label>
                <label>{{this.engineForm.reportTime}}</label>
            </el-row>
        </el-form>
        <el-dialog title="燃油液位统计信息" :visible.sync="EngFuelFormVisible" center :close-on-click-modal="false" append-to-body>
            <el-table :data="EngFuelData" border size="small" stripe style="width: 100%"
                      :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="listLoading">
                <el-table-column prop="engFuelCount" label="燃油液位(L)" align="center"/>
                <el-table-column label="累计运行时间" align="center">
                    <template slot-scope="scope">
                        <div>
                            {{scope.row.genRunningTimeHour}} h
                            {{scope.row.genRunningTimeMin}} m
                            {{scope.row.genRunningTimeSec}} s
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="reportTime" label="上报时间" align="center"/>
            </el-table>
            <div style="margin-top: 15px;text-align: right;">
                <el-pagination background layout="total, prev, pager, next, jumper" :total="dataTotal"
                               @current-change="handleCurrentChange" :current-page.sync="curr"/>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import {queryEngineReportData, queryEngFuelCountData, deviceControl} from "../../../../api/station/deviceInfoApi";
    import websocket from "@/utils/websocket";
    import {getToken} from "@/api/auth";
    import loading from "@/utils/cmdLoading";
    import message from "@/utils/message";
    export default {
        name: "deviceEngineMain",
        props: {
            engineVisible:{
                type: Boolean,
                default: false
            },
        },
        data() {

            return {

                engineForm: {

                    genOutAcv: '0',
                    genOutAvi: '0',
                    genFrequency: '0',
                    genRealP: '0',
                    genReactiveP: '0',
                    genApparentP: '0',
                    genPFactor: '0',

                    genStatus: '停止状态',
                    genVrlaBatV: '0',
                    engPressure: '0',
                    engOilTemp: '0',
                    engLiquidLevel: '0',
                    engFuelCount: '0',
                    engWaterTemp: '0',
                    engRpm: '0',

                    genRunningTimeHour: '0',
                    genRunningTimeMin: '0',
                    genRunningTimeSec: '0',
                    powerOnCount: '0',
                    genTotalEnergy: '0',
                    genCountdownHour: '0',
                    genCountdownMin: '0',

                    mainsV: '0',
                    mainsFrequency: '0',
                    reportTime: '无',
                    dtuWorkStatus: ""
                },
                currWholeId: '',
                currDeviceNum: '',
                deviceStart: false,
                deviceStop: false,
                deviceAuto: false,
                deviceManu: false,
                deviceSwitch: false,
                deviceOnImage: require('../../../../assets/image/on.png'),
                deviceOffImage: require('../../../../assets/image/off.png'),
                deviceImage: '',
                EngFuelFormVisible: false,
                EngFuelData: [],
                listLoading: false,
                dataTotal: 0,
                curr: 1,
                size: 10,
                heZhaText: '合闸',
                topic: "/user/" + getToken() + "/deviceCmd",
                cmdLoading: null
            }
        },
        methods: {

            // 初始化页面
            initPage(id, deviceNum) {

                // 重置数据为默认值
                Object.assign(this.$data, this.$options.data());
                this.currWholeId = id;
                this.currDeviceNum = deviceNum;
                this.initEnginePage();
                // 初始化websocket订阅
                websocket.initWebSocket(this.topic, this.wsCallback);
            },
            // 查询发电机上报数据
            initEnginePage() {

                let params = {

                    deviceNum: this.currDeviceNum
                };
                queryEngineReportData(params).then((res) =>{

                    // 默认为关
                    this.deviceImage = this.deviceOffImage;
                    if (res.data != null) {

                        this.engineForm = res.data;
                        let engLiquidLevel = this.engineForm.engLiquidLevel;
                        this.engineForm.engLiquidLevel = (parseFloat(engLiquidLevel) * 100).toFixed(2);
                        // 根据do判断
                        /*if (res.data.genRunStatus == '启动') {

                            this.deviceImage = this.deviceOnImage;
                            this.deviceStop = true;
                        } else {

                            this.deviceImage = this.deviceOffImage;
                            this.deviceStart = true;
                        }*/
                        // 根据发动机状态判断
                        if (res.data.genStatus == '待机') {

                            this.deviceImage = this.deviceOffImage;
                            this.deviceStart = true;
                        } else {

                            this.deviceImage = this.deviceOnImage;
                            this.deviceStop = true;
                        }
                        // 0-自动 1-手动
                        if (res.data.genMode == '0') {

                            this.deviceManu = true;
                        } else {

                            this.deviceAuto = true;
                        }
                        // 0-分闸 1-合闸
                        if (res.data.genSwitch == '1') {

                            this.deviceSwitch = false;
                            this.heZhaText = "分闸";
                        } else {

                            this.deviceSwitch = true;
                            this.heZhaText = "合闸";
                        }
                    }
                }).catch(err => console.error(err));
            },
            // 取消订阅
            unSub() {

                websocket.unSubTopic(this.topic);
            },
            // websocket回调方法
            wsCallback() {

                console.error("走了指令回调！")
                if (this.cmdLoading) {

                    this.cmdLoading.close();
                }
                message.success("指令下发成功");
                this.initEnginePage();
            },
            // 处理设备操作按钮事件
            handleDeviceOption(id) {

                let params = null;
                if (id == 1) {

                    params = {

                        deviceNumber: this.currDeviceNum,
                        deviceWholeId: this.currWholeId + "",
                        deviceType: "6",
                        commandIndex : "1"
                    };
                } else if (id == 2) {

                    params = {

                        deviceNumber: this.currDeviceNum,
                        deviceWholeId: this.currWholeId + "",
                        deviceType: "6",
                        commandIndex : "0"
                    };
                } else if (id == 3) {

                    params = {

                        deviceNumber: this.currDeviceNum,
                        deviceWholeId: this.currWholeId + "",
                        deviceType: "11",
                        commandIndex : "0"
                    };
                } else if (id == 4) {

                    params = {

                        deviceNumber: this.currDeviceNum,
                        deviceWholeId: this.currWholeId + "",
                        deviceType: "11",
                        commandIndex : "1"
                    };
                } else if (id == 5) {

                    // 使用websocket发送设置指令
                    let cmdIndex = "1";
                    if (this.deviceSwitch == true) {

                        cmdIndex = "0";
                    }
                    params = {

                        deviceNumber: this.currDeviceNum,
                        deviceWholeId: this.currWholeId + "",
                        deviceType: "10",
                        commandIndex : cmdIndex
                    };
                }
                console.error(JSON.stringify(params))
                if (params != null) {

                    deviceControl({message: JSON.stringify(params)}).then((res) => {

                        if (res.code === '100') {

                            this.cmdLoading = loading.loading("指令下发中...", 60);
                        } else {

                            message.error(res.msg);
                        }
                    }).catch(err => console.error(err));
                }
            },
            // 打开液位统计
            openEngFuelCountAnalysis() {

                this.EngFuelFormVisible = true;
                this.getEngFuelCountData();
            },
            // 查询燃油液位信息
            getEngFuelCountData() {

                let params = {

                    size: this.size,
                    curr: this.curr,
                    deviceWholeId: this.currWholeId
                };
                queryEngFuelCountData(params).then((res) =>{

                    this.dataTotal = res.data.totalNum;
                    this.EngFuelData = res.data.listResult;
                }).catch(err => console.error(err));
            },
            // 处理整机设备分页
            handleCurrentChange(val) {

                this.curr = val;
                this.getEngFuelCountData();
            }
        },
        beforeDestroy() {

            // 取消webSocket订阅
            websocket.unSubTopic(this.topic);
        }
    }
</script>
<style scoped>
    .engineForm >>> .totalButton {

        border: 0px;
        background-color: #ECF0F5;
        color: #409EFF;
        font-weight: 900;
    }
</style>
<style>

    .engineForm .el-form-item__label{

        font-weight: bolder;
    }
    .engineForm .el-form-item{

        height: 5px;
    }
    .light {

        width: 15px;
        height: 15px;
        border-radius:50%;
        line-height: 2.5;
        color: white;
        text-align: center;
        background-color: #d5040d;
    }
    .greenLight {

        background-color: #67c23a;
    }
</style>
